import React from 'react'
import ResizeDetector from 'react-resize-detector'
import styled from 'styled-components'
import { FooterControl } from '@thesisedu/react/dist/player/PlayerFooter'
// @ts-ignore
import loadable from '@loadable/component'
import { VideoUnmuteButton } from './VideoUnmuteButton'

const LoadableHlsPlayer = loadable.lib(() => import('@thesisedu/react/dist/player/HlsPlayer'))

export const PROMO_HLS =
  'https://do-vod.prodigiesacademy.com/vod/1b30cffd-7f22-4e40-8f47-a8c9acfb7db2/playlist.m3u8'
export interface VideoFrameProps {
  width: number
  aspect?: number
  muted?: boolean
  autoplay?: boolean
  loop?: boolean
  hlsUrl: string
}
export function VideoFrame({
  width,
  aspect = 9 / 16,
  muted,
  autoplay,
  loop,
  hlsUrl
}: VideoFrameProps) {
  return (
    <LoadableHlsPlayer fallback={<video />}>
      {({ HlsPlayer }: any) => (
        <HlsPlayer
          hlsUrl={hlsUrl}
          autoPlay={autoplay}
          muted={muted}
          loop={loop}
          width={`${width}px`}
          height={`${width * aspect}px`}
          startLevel={5}
          autoLevelBasedOnSizeCapPadding={2}
          playerProps={{
            footerProps: {
              controls: [FooterControl.Volume]
            }
          }}
        />
      )}
    </LoadableHlsPlayer>
  )
}

export interface VideoProps extends Partial<VideoFrameProps> {
  aspect?: number
  hlsUrl: string
}
export function Video({ aspect = 9 / 16, ...props }: VideoProps) {
  return (
    <ResizeDetector
      handleWidth
      render={({ width }) => (
        <Container>
          <VideoFrame {...props} width={width} aspect={aspect} />
        </Container>
      )}
    />
  )
}

export interface VideoFrameWithMuteProps extends VideoFrameProps {
  muteDefault?: boolean
}
export function VideoFrameWithMute({ muteDefault = true, ...props }: VideoFrameWithMuteProps) {
  const [muted, setMuted] = React.useState(muteDefault)
  return (
    <MuteContainer>
      <VideoFrame {...props} muted={muted} />
      {muted ? (
        <UnmuteContainer onClick={() => setMuted(false)}>
          <VideoUnmuteButton onClick={() => setMuted(false)} />
        </UnmuteContainer>
      ) : null}
    </MuteContainer>
  )
}

const MuteContainer = styled.div`
  position: relative;
`
const UnmuteContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 10000;
`
const Container = styled.div`
  border-radius: ${(props) => props.theme['@border-radius-base']};
  overflow: hidden;
  background: ${(props) => props.theme['@background-color-base']};
`
