import React, { useContext, useEffect, useState } from 'react'
import isTouchDevice from 'is-touch-device'
import { FiVolumeX } from 'react-icons/fi'
import { styled, StyledThemeContext } from '@thesisedu/web/dist'

const Container = styled.div<{ isExpanded?: boolean; isMobile?: boolean }>`
  transition: width 0.75s ease-in-out;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  background: ${(props) => props.theme['@gray-6']};
  color: ${(props) => props.theme['@gray-1']};
  flex-wrap: nowrap;
  box-sizing: content-box;
  height: 24px;
  width: ${(props) => (props.isExpanded ? (props.isMobile ? '165px' : '185px') : '24px')};
  box-shadow: 0 1px 2px ${(props) => props.theme['@gray-7']};
  cursor: pointer;
  > span[role='img'], > svg {
    flex-shrink: 0;
  }
  > span {
    flex-shrink: 1;
    margin-left: 16px;
    margin-right: 5px;
    transition: opacity 0.1s linear;
    opacity: ${(props) => (props.isExpanded ? '1' : '0')};
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.5px;
    white-space: nowrap;
    line-height: 1;
    margin-top: 3px;
    font-size: 14px;
  }
`

export interface VideoUnmuteButtonProps {
  onClick: () => void
  style?: any
}
export function VideoUnmuteButton({ onClick, style }: VideoUnmuteButtonProps) {
  const [isExpanded, setIsExpanded] = useState(true)
  const theme = useContext(StyledThemeContext)
  useEffect(() => {
    const interval = setTimeout(() => {
      setIsExpanded(false)
    }, 8000)
    return () => {
      clearInterval(interval)
    }
  })
  return (
    <Container isExpanded={isExpanded} isMobile={isTouchDevice()} onClick={onClick} style={style}>
      <FiVolumeX size={'24px'} color={theme['@gray-1']} />
      <span>{isTouchDevice() ? 'Tap' : 'Click'} to Unmute</span>
    </Container>
  )
}
